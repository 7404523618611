import React from 'react'
import {Link} from 'gatsby'
import Layout from '../../layouts/layout'

class Resources extends React.Component {
  render () {
    return (
<Layout>
  {/* Page Header */}
        <header className="masthead resources">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>NLP Resources</h1>
                  <span className="subheading">NLP is still very much an emerging field - here's a list of some of the best resources our community has compiled!</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <ul>
                <li>The<a href="https://github.com/keon/awesome-nlp">NLP resources list on github is a greate place to start. (We apologize for any repeats/overlaps with our list here)</a></li>
                <li>Interactive Stemming and Lemmatization Website <a href="http://text-processing.com/demo/stem/">here.</a></li>
                <li>Our recommended beginner's NLP bible, the <a href="http://www.nltk.org/book/">NLTK Book</a>, by Steven Bird, Ewan Klein, and Edward Loper.</li>
                <li>If you're looking for a real challenge, Stanford provides a <a href="http://web.stanford.edu/class/cs224n/index.html" target="_blank" rel="noreferrer">Natural Language Processing with Deep Learning course</a>, which appears to be fully publicly accesible. The <a href="http://web.stanford.edu/class/cs224n/syllabus.html" target="_blank" rel="noreferrer">syllabus</a>.</li>
              </ul>
              <p>Have a great resource you think should be on this list? Shoot us an email on the <Link to="/contact">contact page</Link> with the link or resource info!</p>
            </div>
          </div>
        </div>
</Layout>
    );
  }
}

export default Resources;
